
import router from "@/router";
import { userStore } from "@/store/user";
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "Login",
  setup() {
    const isLogin = userStore.isLogin;
    const login = () => {
      userStore.login(() => {
        router.push({ name: "MyJobs" });
      });
    };
    onMounted(() => {
      if (!isLogin.value) {
        login();
      }
    });
    return {
      login,
    };
  },
});
