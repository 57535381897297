
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { JobDetail } from "@/models/jkface";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { boardApi } from "@/lib/jkfaceapi";
import {
  JobDetailRes,
  PersonalJobDetailRes,
} from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-client/job_pb";
import { message } from "ant-design-vue";
import { userStore } from "@/store/user";
// 1 = 待審核
// 2 = 報名成功
// 3 = 本日通告
// 4 = 歷史
enum Step {
  CONTENT = 1,
  CONFIRM = 2,
}
export default defineComponent({
  name: "JobDetail",
  props: {
    trackingNumber: {
      type: String,
      required: true,
    },
    isPersonal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const currentStep: Ref<Step> = ref(Step.CONTENT);
    const isReady = ref(false);
    const accessToken = userStore.accessToken;
    const jobDetail: Ref<JobDetail> = ref(new JobDetail());
    const personalJobType = computed(() => jobDetail.value.personalJobType);
    const fetchJobDetail = async (): Promise<JobDetailRes> => {
      const res = await boardApi.getJobDetail(
        props.trackingNumber,
        accessToken.value
      );
      return res;
    };
    const fetchPersonalJobDetail = async (): Promise<PersonalJobDetailRes> => {
      const res = await boardApi.getPersonalJobDetail(
        props.trackingNumber,
        accessToken.value
      );
      return res;
    };
    const init = async () => {
      isReady.value = false;
      currentStep.value = Step.CONTENT;
      let res: JobDetailRes | PersonalJobDetailRes;
      if (props.isPersonal) {
        res = await fetchPersonalJobDetail();
      } else {
        res = await fetchJobDetail();
      }
      jobDetail.value = new JobDetail(res);
      isReady.value = true;
    };
    const contact = () => {
      window.open(
        "https://line.me/R/ti/p/%40xtf9038y",
        "聯絡官方",
        "width=375, height=375"
      );
    };
    const close = () => {
      context.emit("close");
      currentStep.value = Step.CONTENT;
    };
    const toConfirm = () => {
      currentStep.value = Step.CONFIRM;
    };
    const register = async () => {
      try {
        await boardApi.register(props.trackingNumber, accessToken.value);
        context.emit("onRegister");
        message.success("報名成功", 3);
        jobDetail.value.setIsRegistered(true);
        currentStep.value = Step.CONTENT;
        close();
      } catch (error) {
        console.log(error);
        message.error("報名失敗", 3);
      }
    };
    onMounted(async () => {
      await init();
    });
    return {
      isReady,
      jobDetail,
      contact,
      close,
      toConfirm,
      register,
      currentStep,
      personalJobType,
    };
  },
  components: {
    LoadingOutlined,
  },
});
