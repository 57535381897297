
import { boardApi } from "@/lib/jkfaceapi";
import { JobListItem, Pagination } from "@/models/jkface";
import { PersonalJobType } from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";
import { defineComponent, onBeforeMount, Ref, ref } from "vue";
import JobListItemPersonal from "@/components/JobListItemPersonal.vue";
import { userStore } from "@/store/user";

export default defineComponent({
  name: "MyJobs",
  setup() {
    const searchForm: Ref<{ type: PersonalJobType }> = ref({
      type: PersonalJobType.PJ_CHECKIN,
    });
    const pagination = ref<Pagination>(new Pagination());
    const accessToken = userStore.accessToken;
    const personalJobs = ref<JobListItem[]>([]);
    const fetchPersonalJobs = async (page: number) => {
      pagination.value.current = page;
      personalJobs.value = [];
      const params = searchForm.value;

      const result = await boardApi.getPersonalJobList(
        {
          type: searchForm.value.type,
          // type: PersonalJobType.PJ_CHECKPENDING,
        },
        pagination.value,
        accessToken.value
      );
      personalJobs.value = result.getInfoList().map((e) => new JobListItem(e));
    };
    onBeforeMount(async () => {
      fetchPersonalJobs(1);
    });

    const setSearchFormType = (type: PersonalJobType) => {
      searchForm.value.type = type;
      fetchPersonalJobs(1);
    };
    return { searchForm, setSearchFormType, personalJobs };
  },
  components: {
    JobListItemPersonal,
  },
});
