import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AllJobs from "@/views/AllJobs.vue";
import MyJobs from "@/views/MyJobs.vue";
import Login from "@/views/Login.vue";
import MyReward from "@/views/MyReward.vue";
import Rewards from "@/views/Rewards.vue";
import { userStore } from "@/store/user";
const isLogin = userStore.isLogin;
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter(to, from, next) {
      if (isLogin.value) {
        next({ name: "AllJobs" });
      } else {
        next();
      }
    },
  },
  {
    path: "/alljobs",
    name: "AllJobs",
    component: AllJobs,
    meta: { requiresAuth: true },
  },
  {
    path: "/myjobs",
    name: "MyJobs",
    component: MyJobs,
    meta: { requiresAuth: true },
  },
  {
    path: "/myreward",
    name: "MyReward",
    component: MyReward,
    meta: { requiresAuth: true },
  },
  {
    path: "/rewards",
    name: "Rewards",
    component: Rewards,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!isLogin.value && to.meta.requiresAuth) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
