/* eslint-disable @typescript-eslint/ban-ts-comment */
export function importPan(): Promise<boolean> {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://public.hare200.com/pan/v2.6.8/pan-frontend.umd.min.js";
    document.head.appendChild(script);
    script.onload = () => {
      const jkfhosts = new Map();
      jkfhosts.set("PAN", process.env.VUE_APP_PAN_HOST);
      // @ts-ignore
      window.jkfhosts = jkfhosts;
      // @ts-ignore
      const pan = new PanF2e({
        // @ts-ignore
        tokenUpdated(tokens) {
          console.log("tokenUpdated", tokens);
          // @ts-ignore
          if (window.loginCallback) {
            // @ts-ignore
            window.loginCallback(tokens);
            // @ts-ignore
            window.loginCallback = undefined;
          }
        },
        css: "https://public.hare200.com/pan/v2.6.8/pan-frontend.css",
        recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY,
      });
      // pan.startRefreshTimer();
      // @ts-ignore
      window.pan = pan;
      resolve(true);
    };
  });
}
export function showPan(
  callback?: (tokens: { auth: string; access: string }) => void
): void {
  if (callback) {
    // @ts-ignore
    window.loginCallback = callback;
  }
  // @ts-ignore
  window.pan.open();
}

export const authTokenKeyname = "nekothtua";
