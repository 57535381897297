
import { defineComponent, onBeforeMount, provide, ref, Ref } from "vue";
import { City, JobListItem, Pagination, Cities } from "@/models/jkface";
import { Moment } from "moment";
import { boardApi } from "@/lib/jkfaceapi";
import JobListItemPublic from "@/components/JobListItemPublic.vue";
import { PriceType } from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";
import { DownOutlined } from "@ant-design/icons-vue";
import { momentToTimestamp } from "@/lib/utils";
import { userStore } from "@/store/user";
import { useRoute } from "vue-router";
import JobDetailVue from "@/components/JobDetail.vue";
export default defineComponent({
  name: "AllJobs",
  setup() {
    const route = useRoute();
    const showSearchField = ref(false);
    const cities = Cities;
    const searchForm = ref<{
      range: [undefined, undefined] | [Moment, Moment] | null;
      city: City | undefined;
      type: PriceType;
    }>({
      range: [undefined, undefined],
      city: undefined,
      type: PriceType.PT_DEFAULT,
    });
    const pagination = ref<Pagination>(new Pagination());
    const accessToken = userStore.accessToken;
    const allJobs = ref<JobListItem[]>([]);
    const fetchAllJobs = async (page: number) => {
      pagination.value.current = page;
      allJobs.value = [];
      const params = searchForm.value;

      const result = await boardApi.getAllJobList(
        {
          city: params.city,
          from:
            (params.range &&
              momentToTimestamp(params.range[0]?.startOf("day"))) ||
            undefined,
          to:
            (params.range &&
              momentToTimestamp(params.range[1]?.endOf("day"))) ||
            undefined,
          priceType: params.type,
        },
        pagination.value,
        accessToken.value
      );
      allJobs.value = result.getInfoList().map((e) => new JobListItem(e));
    };

    onBeforeMount(async () => {
      fetchAllJobs(1);
      if (typeof route.query.track === "string") {
        queryJobTrackingNumber.value = route.query.track;
        isQueryJobVisible.value = true;
      }
    });

    const setSearchFormType = (priceType: PriceType) => {
      if (searchForm.value.type === priceType) {
        searchForm.value.type = PriceType.PT_DEFAULT;
      } else {
        searchForm.value.type = priceType;
      }
      fetchAllJobs(1);
    };

    const queryJobTrackingNumber = ref("");
    const isQueryJobVisible = ref(false);

    return {
      showSearchField,
      cities,
      searchForm,
      pagination,
      accessToken,
      fetchAllJobs,
      allJobs,
      setSearchFormType,
      queryJobTrackingNumber,
      isQueryJobVisible,
    };
  },
  components: {
    JobListItemPublic,
    DownOutlined,
    JobDetailVue,
  },
});
