import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment, { Moment } from "moment";

function timestampToMoment(time: Timestamp | undefined): Moment | undefined {
  if (time) {
    return moment.unix(time.getSeconds());
  } else {
    return undefined;
  }
}
function timestampToDayjs(time: Timestamp | undefined): Dayjs | undefined {
  if (time) {
    return dayjs.unix(time.getSeconds());
  } else {
    return undefined;
  }
}

function momentToTimestamp(time: Moment | undefined): Timestamp | undefined {
  if (time) {
    return new Timestamp().setSeconds(time.unix());
  } else {
    return undefined;
  }
}
function dayjsToTimestamp(time: Dayjs | undefined): Timestamp | undefined {
  if (time) {
    return new Timestamp().setSeconds(time.unix());
  } else {
    return undefined;
  }
}

function strictTimestampToMoment(time: Timestamp): Moment {
  return moment.unix(time.getSeconds());
}
function strictTimestampToDayjs(time: Timestamp): Dayjs {
  return dayjs.unix(time.getSeconds());
}

function strictMomentToTimestamp(time: Moment): Timestamp {
  return new Timestamp().setSeconds(time.unix());
}
function strictDayjsToTimestamp(time: Dayjs): Timestamp {
  return new Timestamp().setSeconds(time.unix());
}

const parseJwt = (token: string): { [key: string]: any } => {
  if (!token) {
    return {};
  }
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return {};
  }
};

export {
  timestampToMoment,
  timestampToDayjs,
  momentToTimestamp,
  dayjsToTimestamp,
  strictMomentToTimestamp,
  strictDayjsToTimestamp,
  strictTimestampToMoment,
  strictTimestampToDayjs,
  parseJwt,
};
