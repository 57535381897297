
import { Reward } from "@/models/jkface";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Reward",
  props: {
    expand: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    reward: {
      type: Reward,
      required: true,
    },
  },
});
