
import { defineComponent } from "vue";
import JobListItemTemplate from "@/components/JobListItemTemplate.vue";
import { JobListItem } from "@/models/jkface";
import { PersonalJobType } from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";
export default defineComponent({
  name: "JobListItemReward",
  props: {
    job: {
      type: JobListItem,
      required: true,
    },
    personalJobType: {
      type: Number,
      default: PersonalJobType.PJ_DEFAULT,
    },
  },
  components: {
    JobListItemTemplate,
  },
});
