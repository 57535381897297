import { HostKey } from "@lctech-tw/f2e-doooooooog/lib/dog";

export async function importDog(): Promise<boolean> {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://public.hare200.com/dog/v0.0.86/dog.all.js";
    document.head.appendChild(script);
    script.onload = () => {
      initDog();
      resolve(true);
    };
  });
}
function initDog(): void {
  window.dog.config.setHost(
    HostKey[HostKey.PAN],
    process.env.VUE_APP_PAN_HOST || ""
  );
}
