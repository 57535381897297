export interface User {
  uid: number;
  name: string;
  avatar: string;
}

export const fakeUser: User = {
  uid: -1,
  name: "-",
  avatar: "",
};
