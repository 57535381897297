
import { boardApi } from "@/lib/jkfaceapi";
import { strictDayjsToTimestamp } from "@/lib/utils";
import { Reward } from "@/models/jkface";
import { userStore } from "@/store/user";
import { defineComponent, onBeforeMount, ref, Ref } from "vue";
import RewardVue from "@/components/Reward.vue";
import { RightOutlined } from "@ant-design/icons-vue";
import dayjs, { Dayjs } from "dayjs";

export default defineComponent({
  name: "MyReward",
  setup() {
    const rewardsThisMonth: Ref<Reward> = ref(new Reward());
    const rewardsLastMonth: Ref<Reward> = ref(new Reward());
    const init = async () => {
      rewardsThisMonth.value = await fetchRewards(
        dayjs().startOf("month"),
        dayjs().endOf("month")
      );
      rewardsLastMonth.value = await fetchRewards(
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month")
      );
    };
    const fetchRewards = async (from: Dayjs, to: Dayjs) => {
      const res = await boardApi.getPersonalJobReward(
        strictDayjsToTimestamp(from),
        strictDayjsToTimestamp(to),
        userStore.accessToken.value
      );
      return new Reward(res);
    };
    onBeforeMount(() => {
      init();
    });
    return {
      rewardsThisMonth,
      rewardsLastMonth,
    };
  },
  components: {
    RewardVue,
    RightOutlined,
  },
});
