
import { JobListItem } from "@/models/jkface";
import { defineComponent, inject } from "vue";
import { ref, computed } from "vue";
import JobDetailVue from "@/components/JobDetail.vue";
import { PersonalJobType } from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";
export default defineComponent({
  name: "JobListItemTemplate",
  props: {
    job: {
      type: JobListItem,
      required: true,
    },
    personalJobType: {
      type: Number,
      default: PersonalJobType.PJ_DEFAULT,
    },
  },
  setup(props, context) {
    const isDetailVisible = ref(false);
    const showDetail = () => {
      isDetailVisible.value = true;
    };
    const closeDetail = () => {
      isDetailVisible.value = false;
    };
    const showIdentity = inject("showIdentity", () => undefined);
    const onRegister = () => {
      props.job.setIsRegistered(true);
    };
    const isPersonal = computed(
      () => props.personalJobType !== PersonalJobType.PJ_DEFAULT
    );
    const slotProps = {
      showDetail,
      showIdentity,
    };
    return {
      isDetailVisible,
      showDetail,
      closeDetail,
      showIdentity,
      onRegister,
      isPersonal,
      slotProps,
    };
  },
  components: {
    JobDetailVue,
  },
});
