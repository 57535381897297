import { timestampToMoment } from "@/lib/utils";
import {
  AdsInfo,
  Conditions,
  ConditionsInfo,
  ConditionsType,
  EntityInfo,
  JobTime,
  JobType,
  PersonalJobType,
  PriceType,
} from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";
import {
  JobDetailRes,
  LadyJobInfo,
  PersonalJobDetailRes,
  PersonalJobRewardRes,
} from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-client/job_pb";
import moment, { Moment } from "moment";

enum City {
  "TW-CHA" = "彰化縣",
  "TW-CYQ" = "嘉義縣",
  "TW-HSQ" = "新竹縣",
  "TW-HUA" = "花蓮縣",
  "TW-ILA" = "宜蘭縣",
  "TW-KIN" = "金門縣",
  "TW-LIE" = "連江縣",
  "TW-MIA" = "苗栗縣",
  "TW-NAN" = "南投縣",
  "TW-PEN" = "澎湖縣",
  "TW-PIF" = "屏東縣",
  "TW-TTT" = "臺東縣",
  "TW-YUN" = "雲林縣",
  "TW-CYI" = "嘉義市",
  "TW-HSZ" = "新竹市",
  "TW-KEE" = "基隆市",
  "TW-KHH" = "高雄市",
  "TW-NWT" = "新北市",
  "TW-TAO" = "桃園市",
  "TW-TNN" = "臺南市",
  "TW-TPE" = "臺北市",
  "TW-TXG" = "臺中市",
}
const Cities = {
  "TW-CHA": "彰化縣",
  "TW-CYQ": "嘉義縣",
  "TW-HSQ": "新竹縣",
  "TW-HUA": "花蓮縣",
  "TW-ILA": "宜蘭縣",
  "TW-KIN": "金門縣",
  "TW-LIE": "連江縣",
  "TW-MIA": "苗栗縣",
  "TW-NAN": "南投縣",
  "TW-PEN": "澎湖縣",
  "TW-PIF": "屏東縣",
  "TW-TTT": "臺東縣",
  "TW-YUN": "雲林縣",
  "TW-CYI": "嘉義市",
  "TW-HSZ": "新竹市",
  "TW-KEE": "基隆市",
  "TW-KHH": "高雄市",
  "TW-NWT": "新北市",
  "TW-TAO": "桃園市",
  "TW-TNN": "臺南市",
  "TW-TPE": "臺北市",
  "TW-TXG": "臺中市",
};
class Pagination {
  total = 0;
  current = 1;
  pageSize = 100;
}
class JobListItem {
  id: number;
  trackingNumber: string;
  category: "實體活動" | "業配" = "實體活動";
  priceType: "收費" | "互惠" = "收費";
  title: string;
  city = "";
  address = "";
  entityInfo: {
    totalHrs: number;
    time: string;
    hasMutiplePeriods: boolean;
  } = { totalHrs: 0, time: "", hasMutiplePeriods: false };
  adsInfo: {
    postTime: string;
  } = { postTime: "" };
  basicPay: string;
  myPay: string;
  hasRequiredConditions: boolean;
  hasAdditionalConditions: boolean;
  deadline: string;
  demands: number;
  isRegistered = false;
  constructor(res: LadyJobInfo) {
    this.id = res.getJobId();
    this.trackingNumber = res.getTrackingNum();
    if (res.getJobType() === JobType.JT_AD) {
      this.category = "業配";
    }
    if (res.getPriceType() === PriceType.PT_FREE) {
      this.priceType = "互惠";
    }
    this.title = res.getName();
    this.parseEntityInfo(res.getEntityInfo());
    this.parseAdsInfo(res.getAdsInfo());
    this.basicPay = res.getSinglePrice().toLocaleString();
    this.myPay = res.getTotalPrice().toLocaleString();
    this.hasRequiredConditions =
      res.getConditionType() === ConditionsType.C_REQUIRE;
    this.hasAdditionalConditions =
      res.getConditionType() === ConditionsType.C_ADD;
    this.deadline =
      timestampToMoment(res.getDeadline())?.format("YYYY/MM/DD") || "";
    this.demands = res.getNeedPerson();
    this.isRegistered = res.getIsRegister();
  }
  parseEntityInfo(info?: EntityInfo): void {
    if (info) {
      this.address = info.getAddress();

      const cityCode = info.getCityCode();
      this.city = City[cityCode as keyof typeof City];

      this.entityInfo.totalHrs = info.getTotalHr();
      let mostRecentJobTime: JobTime = new JobTime();
      if (info.getTimeInfoList().length > 1) {
        mostRecentJobTime = info.getTimeInfoList().reduce((a, b) => {
          const a_unix = timestampToMoment(a.getStartAt())?.unix() || 0;
          const b_unix = timestampToMoment(b.getStartAt())?.unix() || 0;
          return a_unix > b_unix ? b : a;
        });

        this.entityInfo.hasMutiplePeriods = true;
      } else {
        mostRecentJobTime = info.getTimeInfoList()[0];
        this.entityInfo.hasMutiplePeriods = false;
      }
      const from: Moment | undefined = timestampToMoment(
        mostRecentJobTime.getStartAt()
      );
      const to: Moment | undefined = timestampToMoment(
        mostRecentJobTime.getEndAt()
      );
      this.entityInfo.time = `${from?.format("YYYY/MM/DD")} ${from?.format(
        "HH:00"
      )} ~ ${to?.format("HH:00")}`;
    }
  }
  parseAdsInfo(info?: AdsInfo): void {
    if (info) {
      this.adsInfo.postTime =
        timestampToMoment(
          (info.getPostDeadline()?.getSeconds() || 0) <= 0
            ? undefined
            : info.getPostDeadline()
        )?.format("YYYY/MM/DD") || "";
    }
  }
  setIsRegistered(bool: boolean): void {
    this.isRegistered = bool;
  }
}

const conditionQuantifier = (info: ConditionsInfo): string => {
  if (info === ConditionsInfo.CI_TOPLADY) {
    return "名";
  } else if (info === ConditionsInfo.CI_IG_FOLLOW) {
    return "人";
  } else if (info === ConditionsInfo.CI_IG_TRACK) {
    return "人";
  } else {
    return "";
  }
};
class HumanJobCondition {
  id: number;
  texts = {
    0: "",
    1: "最新年度百大女郎前",
    2: "IG粉絲人數達",
    3: "FB粉絲專頁追蹤數達",
  };
  type: ConditionsType;
  isRequired: boolean;
  text: string;
  price: number;
  isSelected = false;
  constructor(kon: Conditions) {
    this.id = kon.getId();
    this.type = kon.getType();
    this.text =
      this.texts[kon.getInfo()] +
      kon.getNumber().toLocaleString() +
      conditionQuantifier(kon.getInfo());
    this.price = kon.getPrice();
    this.isRequired = kon.getType() === ConditionsType.C_REQUIRE;
  }
}
class Shift {
  index: number;
  period: string;
  duration: number;
  registerAt = "";
  isRegisterd = false;
  isAfterStart = false;
  constructor(jobTime: JobTime) {
    this.index = jobTime.getIndex();
    const dateFormat = "YYYY/MM/DD";
    const timeFormat = "HH:00";
    const from: Moment = timestampToMoment(jobTime.getStartAt()) || moment();
    const to: Moment = timestampToMoment(jobTime.getEndAt()) || moment();
    const fromDate = from.format(dateFormat);
    const toDate = to.format(dateFormat);
    const fromTime = from.format(timeFormat);
    const toTime = to.format(timeFormat);
    const now = moment();
    if (fromDate === toDate) {
      this.period = `${fromDate} ${fromTime} ~ ${toTime}`;
    } else {
      this.period = `${fromDate} ${fromTime} ~ ${toDate} ${toTime}`;
    }
    this.duration = moment.duration(to.diff(from)).asHours();
    const registerAt: Moment | undefined = timestampToMoment(
      jobTime.getRegisterTime()
    );

    if (now.isBefore(from)) {
      this.registerAt = "尚未開始";
    }
    if (now.isAfter(from)) {
      this.isAfterStart = true;
    }
    if (registerAt) {
      this.registerAt = registerAt.format("HH:mm:ss");
      this.isRegisterd = true;
    }
  }
}
class JobDetail {
  id = 0;
  name = "";
  jobType: JobType = JobType.JT_DEFAULT;
  personalJobType: PersonalJobType = PersonalJobType.PJ_DEFAULT;
  trackingNumber = "";
  priceType: PriceType = PriceType.PT_DEFAULT;
  activityId = 0;
  deadline: Moment | undefined = undefined;
  recruitDemands = 0;
  recruitUnitPrice = 0;
  conditions: Conditions[] = [];
  note = "";
  entityInfo: EntityInfo | undefined = undefined;
  adsInfo: AdsInfo | undefined = undefined;
  isRegistered = false;
  basicPay = "";
  myPay = "";
  shifts: Shift[] = [];
  constructor(res?: JobDetailRes | PersonalJobDetailRes) {
    if (res) {
      this.id = res.getJobId();
      this.name = res.getName();
      this.jobType = res.getJobType();
      this.trackingNumber = res.getTrackingNum();
      this.priceType = res.getPriceType();
      this.deadline = timestampToMoment(res.getDeadline());
      this.recruitDemands = res.getNeedPerson();
      this.recruitUnitPrice = res.getSinglePrice();
      this.conditions = res.getConditionList();
      this.note = res.getMemo();
      this.entityInfo = res.getEntityInfo();
      this.adsInfo = res.getAdsInfo();
      this.basicPay = res.getSinglePrice().toLocaleString();
      this.myPay = res.getTotalPrice().toLocaleString();
      this.shifts =
        this.entityInfo?.getTimeInfoList().map((e) => new Shift(e)) || [];
      if (res instanceof JobDetailRes) {
        this.activityId = res.getActivityId();
        this.isRegistered = res.getIsRegister();
      } else if (res instanceof PersonalJobDetailRes) {
        this.personalJobType = res.getPersonJobType();
      }
    }
  }

  humanConditions(): HumanJobCondition[] {
    return this.conditions.map((e) => {
      return new HumanJobCondition(e);
    });
  }
  requiredCondtions(): HumanJobCondition[] {
    return this.humanConditions().filter((e) => e.isRequired);
  }
  additionalCondtions(): HumanJobCondition[] {
    return this.humanConditions().filter((e) => !e.isRequired);
  }

  periods(): {
    fromDate: string;
    fromTime: string;
    toDate: string;
    toTime: string;
  }[] {
    if (this.entityInfo) {
      return this.entityInfo.getTimeInfoList().map((timeInfo) => {
        const start: Moment =
          timestampToMoment(timeInfo.getStartAt()) || moment();
        const end: Moment = timestampToMoment(timeInfo.getEndAt()) || moment();
        return {
          fromDate: start.format("YYYY/MM/DD"),
          fromTime: start.format("HH:00"),
          toDate: end.format("YYYY/MM/DD"),
          toTime: end.format("HH:00"),
        };
      });
    } else {
      return [];
    }
  }

  humanDeadline(): string {
    return this.deadline?.format("YYYY/MM/DD") || "-";
  }

  location(): string {
    if (this.entityInfo) {
      const cityCode = this.entityInfo.getCityCode();
      const city = City[cityCode as keyof typeof City] || "";
      return `${city} ${this.entityInfo.getAddress()}` || "-";
    } else {
      return "-";
    }
  }

  totalPeriodsHrs(): number {
    if (this.entityInfo) {
      const diffs: number[] = this.entityInfo
        .getTimeInfoList()
        .map((timeInfo) => {
          if (timeInfo.getStartAt() && timeInfo.getEndAt()) {
            const start: Moment =
              timestampToMoment(timeInfo.getStartAt()) || moment();
            const end: Moment =
              timestampToMoment(timeInfo.getEndAt()) || moment();
            const duration = moment.duration(end.diff(start));
            return duration.asHours();
          } else {
            return 0;
          }
        });
      if (diffs.length > 0) {
        return diffs.reduce((a, b) => a + b);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  get humanAdsInfo(): {
    postDeadline: string | undefined;
    postPicTypes: string | undefined;
    postPicNumber: number | undefined;
    postPicIncludeText: boolean;
    adDeadline: string | undefined;
  } {
    const postDeadline = timestampToMoment(this.adsInfo?.getPostDeadline());
    const adDeadline = timestampToMoment(this.adsInfo?.getAdDeadline());
    const type = this.adsInfo?.getPostPicType();
    const postPicTypes = {
      0: undefined,
      1: "至少",
      2: "總計",
    };
    return {
      postDeadline:
        postDeadline?.unix() && postDeadline?.unix() > 0
          ? postDeadline?.format("YYYY/MM/DD")
          : undefined,
      postPicTypes: postPicTypes[type || 0],
      postPicNumber: this.adsInfo?.getPostPicAmount() || undefined,
      postPicIncludeText: this.adsInfo?.getIncludeCompanyText() || false,
      adDeadline:
        adDeadline?.unix() && adDeadline?.unix() > 0
          ? adDeadline?.format("YYYY/MM/DD")
          : undefined,
    };
  }
  setIsRegistered(bool: boolean): void {
    this.isRegistered = bool;
  }
}

class Reward {
  from = "";
  to = "";
  totalReward = 0;
  totalJobAmount = 0;
  entityJobAmount = 0;
  adsJobAmount = 0;
  constructor(res?: PersonalJobRewardRes) {
    if (res) {
      this.from =
        timestampToMoment(res.getStartAt())?.format("YYYY/MM/DD") || "";
      this.to = timestampToMoment(res.getEndAt())?.format("YYYY/MM/DD") || "";
      this.totalReward = res.getReward();
      this.totalJobAmount = res.getTotalAmount();
      this.entityJobAmount = res.getEntityJobAmount();
      this.adsJobAmount = res.getAdJobAmount();
    }
  }
}

export { Pagination, City, Cities, JobListItem, JobDetail, Reward };
