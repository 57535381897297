import Cookies from "js-cookie";
import { Ref, ref } from "vue";
import { authTokenKeyname, showPan } from "@/lib/pan";
import { fakeUser, User } from "@/models/user";

const isLogin = ref(false);
const accessToken = ref("");
const currentUser: Ref<User> = ref(fakeUser);
let timer: number;

const getAccessToken = async (): Promise<string | null> => {
  const authToken = Cookies.get(authTokenKeyname);
  if (authToken) {
    return await window.dog.pan.api
      .AccessToken(authToken)
      .then((res) => res.getAccessToken());
  } else {
    return null;
  }
};
const fetchCurrentUser = async (): Promise<User> => {
  return window.dog.pan.api
    .GetMe(accessToken.value)
    .then((res) => res.getUser())
    .then((u) => {
      if (u) {
        return {
          uid: u?.getUid(),
          name: u?.getDisplayName(),
          avatar: u?.getAvatar(),
        };
      } else {
        return fakeUser;
      }
    });
};
const initUser = async (): Promise<boolean> => {
  try {
    const currentAccessToken = await getAccessToken();
    if (currentAccessToken) {
      accessToken.value = currentAccessToken;
      currentUser.value = await fetchCurrentUser();
      isLogin.value = true;
      startTokenTicker();
    } else {
      currentUser.value = fakeUser;
      isLogin.value = false;
    }
  } catch (error) {
    isLogin.value = false;
    currentUser.value = fakeUser;
  }
  return isLogin.value;
};

const refreshToken = async (): Promise<boolean> => {
  try {
    const currentAccessToken = await getAccessToken();
    if (currentAccessToken) {
      accessToken.value = currentAccessToken;
      isLogin.value = true;
      return true;
    } else {
      isLogin.value = false;
      return false;
    }
  } catch (error) {
    isLogin.value = false;
    return false;
  }
};

const startTokenTicker = () => {
  timer = window.setInterval(async () => {
    const result = await refreshToken();
    if (result === false) {
      clearInterval(timer);
    }
  }, 60000);
};

const login = (callback?: () => void) => {
  showPan(async (tokens) => {
    Cookies.set(authTokenKeyname, tokens.auth, { expires: 365 });
    await initUser();
    callback && callback();
  });
};

const logout = () => {
  Cookies.remove(authTokenKeyname);
  accessToken.value = "";
  currentUser.value = fakeUser;
  isLogin.value = false;
  clearInterval(timer);
};

export const userStore = {
  isLogin,
  currentUser,
  accessToken,
  login,
  logout,
  initUser,
};
