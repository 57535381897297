
import { defineComponent, ref, onMounted, Ref } from "vue";
import { useQRCode } from "@vueuse/integrations/useQRCode";
import { userStore } from "@/store/user";
import { parseJwt } from "@/lib/utils";

export default defineComponent({
  name: "Identity",
  setup(props, context) {
    const currentUser = userStore.currentUser;
    const close = () => {
      context.emit("close");
    };
    const qrcode = ref("");
    const remainSeconds: Ref<number> = ref(0);
    const expireAt: Ref<number> = ref(0);
    const getQRcode = async () => {
      const res = await window.dog.pan.api.GetQRCode({
        panAccessToken: userStore.accessToken.value,
      });
      qrcode.value = res.getImageContent();
      const payload = parseJwt(res.getVerifyCode());
      expireAt.value = payload["expire"];
      tick();
    };
    const tick = () => {
      remainSeconds.value = expireAt.value - Math.floor(Date.now() / 1000);
      if (remainSeconds.value <= 0) {
        getQRcode();
      }
    };
    const ticker = ref(
      setInterval(() => {
        return;
      })
    );
    onMounted(() => {
      getQRcode();
      ticker.value = setInterval(() => {
        tick();
      }, 1000);
    });
    return { qrcode, currentUser, close, remainSeconds };
  },
});
