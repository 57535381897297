
import { defineComponent } from "vue";
import JobListItemTemplate from "@/components/JobListItemTemplate.vue";
import { JobListItem } from "@/models/jkface";
export default defineComponent({
  name: "JobListItemPublic",
  props: {
    job: {
      type: JobListItem,
      required: true,
    },
  },
  components: {
    JobListItemTemplate,
  },
});
