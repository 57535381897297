import { createApp } from "vue";

import Antd from "ant-design-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "ant-design-vue/dist/antd.css";
import "@/store/user";
import { userStore } from "@/store/user";
import { importPan } from "./lib/pan";
import { importDog } from "./lib/dog";

import dayjs from "dayjs";
import "dayjs/locale/zh-tw";
dayjs.locale("zh-tw");

const start = async () => {
  await importPan();
  await importDog();
  await userStore.initUser();
  createApp(App).use(Antd).use(router).mount("#app");
};

start();
