
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { RollbackOutlined } from "@ant-design/icons-vue";
import dayjs, { Dayjs } from "dayjs";
import { boardApi } from "@/lib/jkfaceapi";
import { JobListItem, Pagination, Reward } from "@/models/jkface";
import { userStore } from "@/store/user";
import { strictDayjsToTimestamp } from "@/lib/utils";
import JobListItemReward from "@/components/JobListItemReward.vue";
import { PersonalJobType } from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";

export default defineComponent({
  name: "Rewards",
  components: {
    RollbackOutlined,
    JobListItemReward,
  },
  setup() {
    const personJobType = PersonalJobType.PJ_HISTORY;
    const pagination = ref<Pagination>(new Pagination());
    const searchForm = ref<{
      range: Dayjs;
    }>({
      range: dayjs(),
    });
    const isThisMonth = computed(() => {
      return searchForm.value.range?.month() === dayjs().month();
    });
    const isLastMonth = computed(() => {
      return (
        searchForm.value.range?.month() === dayjs().subtract(1, "month").month()
      );
    });
    const setRange = async (option?: "thisMonth" | "lastMonth") => {
      if (option === "thisMonth") {
        searchForm.value.range = dayjs().startOf("month");
      } else if (option === "lastMonth") {
        searchForm.value.range = dayjs().subtract(1, "month").startOf("month");
      }
      await fetchPersonalJobs(1);
      await fetchRewards();
    };
    const fetchPersonalJobs = async (page: number) => {
      pagination.value.current = page;
      personalJobs.value = [];
      const params = searchForm.value;
      const from = strictDayjsToTimestamp(params.range.startOf("month"));
      const to = strictDayjsToTimestamp(params.range.endOf("month"));
      const result = await boardApi.getPersonalJobList(
        {
          type: PersonalJobType.PJ_HISTORY,
          from: from,
          to: to,
        },
        pagination.value,
        userStore.accessToken.value
      );
      personalJobs.value = result.getInfoList().map((e) => new JobListItem(e));
    };
    const fetchRewards = async () => {
      const params = searchForm.value;
      const from = strictDayjsToTimestamp(params.range.startOf("month"));
      const to = strictDayjsToTimestamp(params.range.endOf("month"));
      const res = await boardApi.getPersonalJobReward(
        from,
        to,
        userStore.accessToken.value
      );
      rewardsSelectedMonth.value = new Reward(res);
    };
    const rewardsSelectedMonth: Ref<Reward> = ref(new Reward());
    const personalJobs = ref<JobListItem[]>([]);
    const init = async () => {
      fetchPersonalJobs(1);
      fetchRewards();
    };
    onMounted(() => {
      init();
    });
    return {
      searchForm,
      isThisMonth,
      isLastMonth,
      setRange,
      rewardsSelectedMonth,
      personalJobs,
      personJobType,
    };
  },
});
