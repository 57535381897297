
import { defineComponent } from "vue";
import Layout from "@/layouts/layout.vue";
import { userStore } from "@/store/user";
import zhTW from "ant-design-vue/es/locale/zh_TW";

export default defineComponent({
  name: "Jcontrol",
  components: {
    Layout,
  },
  setup() {
    const isLogin = userStore.isLogin;
    const locale = zhTW;
    return {
      isLogin,
      locale,
    };
  },
});
