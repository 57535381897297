
import { defineComponent, provide, ref } from "vue";
import Identity from "@/components/Identity.vue";
import { userStore } from "@/store/user";
export default defineComponent({
  name: "Layout",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  setup() {
    const currentUser = userStore.currentUser;
    const isAboutVisible = ref(false);
    const showAbout = () => {
      isAboutVisible.value = true;
    };
    const isIdentityVisible = ref(false);
    const showIdentity = () => {
      isIdentityVisible.value = true;
    };
    const hideIdentity = () => {
      isIdentityVisible.value = false;
    };
    provide("showIdentity", showIdentity);
    provide("hideIdentity", hideIdentity);
    return {
      currentUser,
      isIdentityVisible,
      showIdentity,
      hideIdentity,
      isAboutVisible,
      showAbout,
    };
  },
  methods: {
    logout() {
      userStore.logout();
      this.$router.push({ name: "Login" });
    },
  },
  components: {
    Identity,
  },
});
