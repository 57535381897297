/* eslint-disable @typescript-eslint/ban-ts-comment */
import { JobServicePromiseClient } from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-client/job_grpc_web_pb.js";
import * as boardClientPb from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-client/job_pb";
import {
  AdsInfo,
  Conditions,
  ConditionsInfo,
  ConditionsType,
  EntityInfo,
  JobStatus,
  JobTime,
  JobType,
  PersonalJobType,
  PostPicType,
  PriceType,
  SelectedLadyInfo,
  Team,
} from "@lctech-tw/jkface-proto/dist/js/jkf-board/board-atom/atom_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Pagination } from "@/models/jkface";

const service = () => {
  const client = new JobServicePromiseClient(
    process.env.VUE_APP_JKFBOARD_HOST || ""
  );
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
  enableDevTools([client]);
  return client;
};

const getAllJobList = async (
  payload: {
    city?: string;
    from?: Timestamp;
    to?: Timestamp;
    priceType?: PriceType;
  },
  pagination: Pagination,
  token: string
): Promise<boardClientPb.AllJobListRes> => {
  const req: boardClientPb.AllJobListReq = new boardClientPb.AllJobListReq();
  let res: boardClientPb.AllJobListRes;
  req.setAccessToken(token);
  payload.city && req.setCity(payload.city);
  if (payload.from && payload.to) {
    req.setJobStartAtFrom(payload.from);
    req.setJobStartAtTo(payload.to);
  }
  payload.priceType && req.setPriceType(payload.priceType);
  req.setPage(pagination.current);
  req.setLimit(pagination.pageSize);
  try {
    res = await service().allJobList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const register = async (
  trackingNumber: string,
  token: string
): Promise<boardClientPb.RegisterJobRes> => {
  const req: boardClientPb.RegisterJobReq = new boardClientPb.RegisterJobReq();
  let res: boardClientPb.RegisterJobRes;
  req.setAccessToken(token);
  req.setTrackingNum(trackingNumber);
  try {
    res = await service().registerJob(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getJobDetail = async (
  trackingNumber: string,
  token: string
): Promise<boardClientPb.JobDetailRes> => {
  const req: boardClientPb.JobDetailReq = new boardClientPb.JobDetailReq();
  let res: boardClientPb.JobDetailRes;
  req.setAccessToken(token);
  req.setTrackingNum(trackingNumber);
  try {
    res = await service().jobDetail(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getPersonalJobList = async (
  payload: {
    type: PersonalJobType;
    from?: Timestamp;
    to?: Timestamp;
  },
  pagination: Pagination,
  token: string
): Promise<boardClientPb.PersonalJobListRes> => {
  const req: boardClientPb.PersonalJobListReq =
    new boardClientPb.PersonalJobListReq();
  let res: boardClientPb.PersonalJobListRes;
  req.setAccessToken(token);
  if (payload.from && payload.to) {
    req.setStartAt(payload.from);
    req.setEndAt(payload.to);
  }
  req.setLimit(pagination.pageSize);
  req.setPage(pagination.current);
  req.setPersonJobType(payload.type);
  try {
    res = await service().personalJobList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getPersonalJobDetail = async (
  trackingNumber: string,
  token: string
): Promise<boardClientPb.PersonalJobDetailRes> => {
  const req: boardClientPb.PersonalJobDetailReq =
    new boardClientPb.PersonalJobDetailReq();
  let res: boardClientPb.PersonalJobDetailRes;
  req.setAccessToken(token);
  req.setTrackingNum(trackingNumber);
  try {
    res = await service().personalJobDetail(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getPersonalJobReward = async (
  from: Timestamp,
  to: Timestamp,
  token: string
): Promise<boardClientPb.PersonalJobRewardRes> => {
  const req: boardClientPb.PersonalJobRewardReq =
    new boardClientPb.PersonalJobRewardReq();
  let res: boardClientPb.PersonalJobRewardRes;
  req.setAccessToken(token);
  req.setStartAt(from);
  req.setEndAt(to);
  try {
    res = await service().personalJobReward(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const boardApi = {
  getAllJobList,
  register,
  getJobDetail,
  getPersonalJobList,
  getPersonalJobDetail,
  getPersonalJobReward,
};
