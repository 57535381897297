import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-653f61b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jcontrol" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.locale }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLogin)
          ? (_openBlock(), _createBlock(_component_Layout, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}